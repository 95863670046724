var searchKeys = [{
  key: "status",
  label: '状态',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };