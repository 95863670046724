var columns = [{
  title: 'ID',
  dataIndex: 'activityid',
  key: 'activityid',
  width: '18%',
  scopedSlots: {
    customRender: 'activityid'
  },
  align: 'center'
}, {
  title: '活动名称',
  dataIndex: 'name',
  key: 'name',
  width: '15%',
  scopedSlots: {
    customRender: 'name'
  },
  align: 'center'
}, {
  title: '活动开始日期(UTC)',
  dataIndex: 'startdate',
  key: 'startdate',
  width: 120,
  scopedSlots: {
    customRender: 'startdate'
  },
  align: 'center'
}, {
  title: '活动结束日期(UTC)',
  dataIndex: 'enddate',
  key: 'enddate',
  width: 120,
  scopedSlots: {
    customRender: 'enddate'
  },
  align: 'center'
}, {
  title: '创建时间(UTC)',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '15%',
  scopedSlots: {
    customRender: 'createtime'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: 80,
  scopedSlots: {
    customRender: 'status'
  },
  align: 'center'
}, {
  title: '已发放CBK',
  dataIndex: 'cbkrelease',
  key: 'cbkrelease',
  width: 100,
  scopedSlots: {
    customRender: 'cbkrelease'
  },
  align: 'center'
}, {
  title: '参与人数',
  dataIndex: 'joinuser',
  key: 'joinuser',
  width: 100,
  scopedSlots: {
    customRender: 'joinuser'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns };